import { Box, Container } from '@mui/material';
import React from 'react';
import { HexColorInput, HexColorPicker } from "react-colorful";

interface ColorPickerComponentProps {
    color: string;
    onColorChange: (newColor: string) => void;
}

const ColorPickerComponent: React.FC<ColorPickerComponentProps> = ({ color, onColorChange }) => {
    return (<>
        <Container maxWidth="sm" style={{ textAlign: 'center', color: '#FFFFFF' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 3,
                }}
            >
                <HexColorPicker color={color} onChange={onColorChange} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 3,
                }}
            >
                Chosen color:
                <HexColorInput color={color} onChange={onColorChange} />
            </Box >
        </Container >
    </>
    );
};

export default ColorPickerComponent;